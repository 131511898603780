import Resources from './resources';
import { getNextFlags } from './get-next-flags/get-next-flags';
import { getLocale } from './utils';

const getModules = ({ userId, module, girlId }) => {
  const locale = getLocale();
  const withParams = {};
  if (locale) {
    withParams.locale = locale;
  }
  return Resources[userId ? 'getTeacherModules' : 'getStudentModules']
    .get({
      userId,
      module,
      girlId
    })
    .withParams(withParams)
    .send();
};

const saveModules = async ({ userId, moduleName, moduleType, girlId, flags, payload, user, action }) => {
  let from = flags;
  let to = getNextFlags({
    user,
    action,
    flags
  });
  let response;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const withParams = {
      flags: {
        from,
        to
      },
      content: payload
    };
    const locale = getLocale();
    if (locale) {
      withParams.locale = locale;
    }
    // eslint-disable-next-line no-await-in-loop
    response = await Resources[userId ? 'saveTeacherModule' : 'saveStudentModule']
      .post({
        userId,
        moduleName,
        moduleType,
        resourceId: girlId
      })
      .withBody(withParams)
      .send();

    if (!(response.status === 200 || response.status === 204)) {
      break;
    }
    if (response.data.retry) {
      from = response.data.meta.flags;
      to = getNextFlags({
        user,
        action,
        flags: from
      });
    } else {
      break;
    }
  }
  return response;
};

const API = {
  getModules,
  saveModules
};

export default API;
