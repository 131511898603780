import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InlineSVG from 'react-inlinesvg';
import _ from 'lodash';
import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import iconTick from '../../assets/images/icon_tick_01.svg';
import iconCross from '../../assets/images/icon_cross_01.svg';
import iconExclamation from '../../assets/images/icon_exclamation_01.svg';
import iconTeacherNotes from '../../assets/images/icon_teacher_notes.svg';
import css from './footer.scss';
import {
  saveSegment,
  completeActivity,
  recordTime,
  TIMESTAMP_CHECK,
  TIMESTAMP_COMPLETE
} from '../mle/mle-actions';
import { BtnText } from '../../components/buttons';
import { setActivityStatus, ACTIVITY_MARKED, ACTIVITY_UNATTEMPTED } from '../activity/activity-actions';
import { MODULE_NAME_ACTIVITY, userRoles, RESULT_TYPES } from '../../helpers/constants';
import CheckConfirmModal from './check-confirm-modal';
import { constructModal, activateModal } from '../modal/modal-actions';
import InfoFeedback from '../info-feedback/info-feedback';
import { getActivityResult } from '../../helpers/utils';
import { BtnIcon } from '../../components/buttons/btn-icon/btn-icon';
import Resources from '../../helpers/resources';
import Info from '../info/info';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const Footer = () => {
  const { i18n } = useLocalisation();
  const {
    activityReducer,
    queryStringReducer,
    initMleReducer,
    proveletReducer,
    userReducer
  } = useSelector(state => state);
  const dispatch = useDispatch();

  const isTeacher = userReducer.userRole === userRoles.TEACHER || queryStringReducer.isPreview;

  const closeConfirmationModal = () => {
    dispatch(activateModal(false));
  };

  const onCheckSuccess = (response) => {
    dispatch(setActivityStatus(ACTIVITY_MARKED, response));
    closeConfirmationModal();
  };

  const handleCheckBtn = () => {
    const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
    if (queryStringReducer.isPreview) {
      const activityResult = getActivityResult(activityReducer.activityState || {});
      onCheckSuccess({
        ...activityData,
        meta: {
          ...activityData.meta,
          user: {
            ...activityData.meta.user,
            result: activityResult
          }
        }
      });
      return;
    }
    if (initMleReducer.length) {
      dispatch(saveSegment({
        content: { activityState: activityReducer.activityState, activityStatus: ACTIVITY_MARKED },
        girlId: queryStringReducer.girlId,
        moduleType: activityData.meta.type,
        savedCallBack: onCheckSuccess,
        moduleName: MODULE_NAME_ACTIVITY
      }));
      dispatch(recordTime(TIMESTAMP_CHECK));
    }
  };

  const showConfirmationModal = () => {
    dispatch(constructModal({
      activate: true,
      content: <CheckConfirmModal
        handleCheck={handleCheckBtn}
        handleCancel={closeConfirmationModal}
      />,
      canvasOnClick: false
    }));
  };

  const completeCallback = () => {
    window.history.back();
  };

  const handleSubmitBtn = async () => {
    if (isTeacher) {
      completeCallback();
      return;
    }
    await dispatch(recordTime(TIMESTAMP_COMPLETE));
    dispatch(completeActivity({
      girlId: queryStringReducer.girlId,
      completeCallback
    }));
  };

  const isDisabled = () => {
    let isCheckDisabled = false;
    let isSubmitDisabled = false;
    const { isChecking, isCheckingStatus, activityStatus, activityState, isCompleted, isAssigned } = activityReducer;
    const { isEditing } = proveletReducer;
    const isNonMarking = !activityState || !activityState.total;
    if (isChecking || isEditing) {
      isCheckDisabled = true;
      isSubmitDisabled = true;
    }
    if (activityStatus === ACTIVITY_MARKED
      || isNonMarking) {
      isCheckDisabled = true;
    }
    if (((activityStatus === ACTIVITY_UNATTEMPTED || isCheckingStatus) && !isNonMarking)
      || isCompleted
      || !isAssigned) {
      isSubmitDisabled = true;
    }
    return { isCheckDisabled, isSubmitDisabled };
  };

  let labelIconUrl;

  const getCheckLabel = () => {
    const { activityResult: { type, total, correct } } = activityReducer;
    let labelText = i18n._(t`Check my answer`);

    switch (type) {
      case RESULT_TYPES.CORRECT:
        labelText = i18n._(t`All parts correct`);
        labelIconUrl = iconTick;
        break;
      case RESULT_TYPES.INCORRECT:
        labelText = i18n._(t`All parts incorrect`);
        labelIconUrl = iconCross;
        break;
      case RESULT_TYPES.PARTIAL: {
        const translatedPartsCorrect = i18n._(t`parts correct`);
        labelText = `${correct} / ${total} ${translatedPartsCorrect}`;
        labelIconUrl = iconExclamation;
        break;
      }
      default:
        labelIconUrl = '';
        break;
    }

    return (
      <React.Fragment>
        {labelIconUrl && <InlineSVG src={labelIconUrl} />}
        <span className={css['btn-label-text']}>{labelText}</span>
      </React.Fragment>
    );
  };

  const checkLabel = getCheckLabel();

  const getTeacherNotesResourceId = () => {
    const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
    return _.get(activityData, 'meta.teacherNotes');
  };

  const handleTeacherNotes = () => {
    window.open(
      // eslint-disable-next-line max-len
      `${window._env_.TeacherNotesUrl}/${queryStringReducer.girlId}?authToken=${Resources.env.authToken}&locale=${i18n.language}`
    );
  };

  return (
    <div className={classNames(css.footer, { [css.hide]: proveletReducer.widgetEditor === 'Scan' })}>
      <div className={css['psr-side']}>
        <BtnText
          data-testid="check-button"
          className={labelIconUrl ? css['btn-info'] : ''}
          callBack={showConfirmationModal}
          isDisabled={isDisabled().isCheckDisabled}
        >
          {checkLabel}
        </BtnText>
        {
          isTeacher && getTeacherNotesResourceId() &&
          <BtnIcon
            icon={iconTeacherNotes}
            className={css['btn-teacher-notes']}
            callBack={handleTeacherNotes}
          />
        }
        <div className={css['misc-container']}>
          <Info />
          <InfoFeedback className={css['info-feedback-margin']} />
        </div>
      </div>
      <BtnText
        className={css.btn}
        callBack={handleSubmitBtn}
        isDisabled={isDisabled().isSubmitDisabled}
      >
        <Trans>Finish</Trans>
      </BtnText>
    </div>
  );
};

export default Footer;
