import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { MODULE_NAME_ACTIVITY } from '../../../helpers/constants';
import Resources from '../../../helpers/resources';

export const useValidate = () => {
  const { initMleReducer, queryStringReducer, conquestReducer } = useSelector(state => state);
  const { answer } = conquestReducer;
  const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);

  const validate = async () => {
    const res = await Resources.saveActivityModules
      .post()
      .withBody({
        moduleName: MODULE_NAME_ACTIVITY,
        moduleType: activityData.meta.type,
        resourceId: queryStringReducer.girlId,
        studentAnswer: answer,
        locale: queryStringReducer.locale
      })
      .send();

    return res;
  };

  return { ...useMutation({
    mutationFn: validate
  }) };
};
