/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { QtcRenderer, ReadAloud } from '@3plearning/question-components-library';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Img, Portal, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import Draggable from 'react-draggable';
import { MODULE_NAME_ACTIVITY } from '../../helpers/constants';
import {
  disableSpinner,
  ACTIVITY_RENDER_SPINNER
} from '../mle/mle-actions';
import { updateAnswer } from './conquest.actions';
import css from './conquest.scss';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { useReadAloud } from './hooks/use-read-aloud';
import { useKeypad } from './hooks/use-keypad';
import dragIcon from '../../assets/images/Move.svg';
import keypadIcon from '../../assets/images/keypad.svg';

const Conquest = () => {
  const keypadRef = useRef(null);
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const { handleDrag, checkInjectedKeypad, showKeypad, isKeypadActivated, setIsKeypadActivated } = useKeypad();
  const [question, setQuestion] = useState([]);
  const { initMleReducer, conquestReducer, queryStringReducer } = useSelector(state => state);
  const { readAloudConfig, onLocaleChange, onToggleEnableState } = useReadAloud();
  let activityData;

  const feedback = ({ answer, isReady }) => {
    if (isReady) {
      dispatch(updateAnswer({ answer, isReady }));
    }
  };

  useEffect(() => {
    onLocaleChange(queryStringReducer.locale);
    dispatch(disableSpinner(ACTIVITY_RENDER_SPINNER));

    if (initMleReducer.length > 0) {
      activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
      setQuestion(activityData.questionDetails.questionVariant.questionItem.questionContent.content);
    }

    if (activityData.data) {
      dispatch(updateAnswer({
        answer: activityData.data.studentAnswer,
        isReady: false,
        validation: activityData.data.validationResult
      }));
    }
  }, []);

  useEffect(() => {
    if (keypadRef.current) {
      checkInjectedKeypad(keypadRef);
    }
  }, [keypadRef.current]);

  return (
    <React.Fragment>
      <Portal>
        <Draggable
          bounds="body"
          onDrag={evt => handleDrag(evt)}
          onStart={evt => handleDrag(evt)}
          onStop={evt => handleDrag(evt)}
        >
          <Box
            className={css['keypad-container']}
            zIndex={showKeypad && isKeypadActivated ? 1000 : -1}
            ref={keypadRef}
          >
            <Box className={css['keypad-container-header']}>
              <Img
                className={css['drag-icon']}
                src={dragIcon}
              />
            </Box>
          </Box>
        </Draggable>
      </Portal>
      <Box className={css['read-aloud-container']}>
        <ReadAloud
          onLocaleChange={locale => onLocaleChange(locale)}
          onToggleEnableState={enabled => onToggleEnableState(enabled)}
          popoverLabel={i18n._(t`Read aloud`)}
          selectedLocale={/es-/ig.test(queryStringReducer.locale) ? 'es-ES' : queryStringReducer.locale}
          disabled
        />
      </Box>
      <Button
        className={`${css['keypad-btn']} ${isKeypadActivated && css['keypad-activated']}`}
        onClick={() => setIsKeypadActivated(!isKeypadActivated)}
      >
        <Img src={keypadIcon} />
      </Button>
      <QtcRenderer
        question={question}
        callback={feedback}
        validation={conquestReducer.validation}
        keypadPreset={3}
        keypadContainer={keypadRef.current}
        readAloudConfig={readAloudConfig}
        userResponse={conquestReducer.answer}
      />
      {
        conquestReducer.validation && !conquestReducer.validation.isCorrect && (
          <Box className={css['correct-answer']} >
            <Text className={css['correct-answer-text']} >
              {i18n._(t`Here's the correct answer`)}
            </Text>
            <Box className={css['correct-answer-container']}>
              <QtcRenderer
                callback={() => undefined}
                question={question}
                showCorrectAnswer
                validation={conquestReducer.validation}
              />
            </Box>
          </Box>
        )
      }
    </React.Fragment>
  );
};

export default Conquest;
