import BasicResources from './basic-resources';

class Resources {
  static createResources(parentEnv) {
    if (parentEnv) {
      Resources.env = parentEnv;
    }

    const env = Resources.env;

    const BASE_URL = window._env_.MLEGateway;

    /* TODO:vivek: refer resourceModules from constants.js and remove duplicate implementation
    _.forOwn(resourceModules, (value, key) => {
      Resources[key] = Resources.env
        .resource(key)
        .baseUrl(BASE_URL)
        .service(value);
    });
    */

    Resources.getTeacherModules = Resources.env
      .resource('getTeacherModules')
      .baseUrl(BASE_URL)
      .service('users/{userId}/modules/{module}/resources/{girlId}');

    Resources.getStudentModules = Resources.env
      .resource('getStudentModules')
      .baseUrl(BASE_URL)
      .service('modules/{module}/resources/{girlId}');

    Resources.saveStudentModule = Resources.env
      .resource('saveStudentModule')
      .baseUrl(BASE_URL)
      .service('/modules/{moduleName}/types/{moduleType}/resources/{resourceId}');

    Resources.saveTeacherModule = Resources.env
      .resource('saveTeacherModule')
      .baseUrl(BASE_URL)
      .service('/users/{userId}/modules/{moduleName}/types/{moduleType}/resources/{resourceId}');

    Resources.getStudentProveletData = Resources.env
      .resource('getStudentProveletData')
      .baseUrl(BASE_URL)
      .service('/modules/2/resources/{resourceId}');

    Resources.getTeacherProveletData = Resources.env
      .resource('getTeacherProveletData')
      .baseUrl(BASE_URL)
      .service('/users/{userId}/modules/2/resources/{resourceId}');

    Resources.saveResource = Resources.env
      .resource('saveResource')
      .baseUrl(BASE_URL)
      .service('/resources/{resourceId}/files');

    Resources.saveTeacherResource = Resources.env
      .resource('saveTeacherResource')
      .baseUrl(BASE_URL)
      .service('/users/{userId}/resources/{resourceId}/files');

    Resources.completeActivity = Resources.env
      .resource('completeActivity')
      .baseUrl(BASE_URL)
      .service('/complete');

    Resources.events = Resources.env
      .resource('events')
      .baseUrl(BASE_URL)
      .service('/events');

    Resources.getActivityStatus = Resources.env
      .resource('getActivityStatus')
      .baseUrl(BASE_URL)
      .service('/products/{productId}/resources/{resourceId}/status');

    Resources.dismiss = Resources.env
      .resource('dismiss')
      .baseUrl(BASE_URL)
      .service('/products/{productId}/resources/{resourceId}/dismiss');

    Resources.assignDefault = Resources.env
      .resource('assignDefault')
      .baseUrl(BASE_URL)
      .service('/assignDefault');

    Resources.saveActivityModules = Resources.env
      .resource('saveActivityModules')
      .baseUrl(BASE_URL)
      .service('/saveActivityModules');

    Resources.getConfigurationItem = async key => env.getConfigurationItem(key);
  }

  static initialise(envName, credentials) {
    Resources.env = BasicResources.initialise(envName, credentials);

    Resources.createResources();

    return Resources.env;
  }
}

export default Resources;

