import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AR } from 'js-aruco';
import { Trans } from '@lingui/macro';
import InlineSVG from 'react-inlinesvg';
import classNames from 'classnames';
import iconPrint from '../../../assets/images/ico_printer.svg';
import { BtnText } from '../../../components/buttons';
import { widgetSave, widgetCancel } from '../../provelet/provelet-actions';
import { resetMenu } from '../../widget-menu/widget-menu-actions';
import css from './scan.scss';
import { constructModal, activateModal } from '../../modal/modal-actions';
import ScanPaperModal from '../../../components/scan-paper-modal/scan-paper-modal';
import leftGreen from '../../../assets/images/clevergreen_left.svg';
import rightGreen from '../../../assets/images/clevergreen_right.svg';
import scanPaperTemplate from '../../../assets/pdfs/scan-paper-template.pdf';
import { pdfHtml } from '../../../helpers/constants';

const ScanEdit = forwardRef((props, externalRef) => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [hasCapture, setHasCapture] = useState(false);
  const dispatch = useDispatch();

  useImperativeHandle(externalRef, () => ({
    getData() {
      return {
        content: {
          canvas: canvasRef.current.toDataURL()
        }
      };
    }
  }));

  const TOP_LEFT = 0;
  const TOP_RIGHT = 1;
  const BOTTOM_LEFT = 2;
  const BOTTOM_RIGHT = 3;
  const MAX_TOLERANCE = 60;
  const TOLERANCE_INTERVAL = 10;
  const FRAME_RATE = 25;
  const detector = new AR.Detector();

  const isAllMarkersDetected = (detectedMarkers) => {
    const markers = {};
    detectedMarkers.forEach((marker) => { markers[marker.id] = marker; });
    const allMarkersDetected = Object.keys(markers).length === 4;
    if (allMarkersDetected) {
      const ORIENTATION_OFFSET = 25;
      const isFlipped = markers[TOP_LEFT].corners[0].y > markers[BOTTOM_LEFT].corners[3].y;
      const alignDiffTop = Math.abs(markers[TOP_RIGHT].corners[1].y - markers[TOP_LEFT].corners[0].y);
      const alignDiffBottom = Math.abs(markers[BOTTOM_RIGHT].corners[2].y - markers[BOTTOM_LEFT].corners[3].y);
      if (isFlipped || alignDiffTop > ORIENTATION_OFFSET || alignDiffBottom > ORIENTATION_OFFSET) {
        return false;
      }
    }
    return allMarkersDetected;
  };

  const detectMarkers = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const dataSets = [];
    let index = 0;

    for (let tolerance = 0; tolerance <= MAX_TOLERANCE; tolerance += TOLERANCE_INTERVAL) {
      dataSets[index] = context.getImageData(0, 0, canvas.width, canvas.height);
      index += 1;
    }

    for (let i = 0; i < dataSets[0].data.length; i += 4) {
      index = 0;
      for (let tolerance = 0; tolerance <= MAX_TOLERANCE; tolerance += TOLERANCE_INTERVAL) {
        const avg = (dataSets[index].data[i] + dataSets[index].data[i + 1] + dataSets[index].data[i + 2]) / 3;

        dataSets[index].data[i] = avg < tolerance ? 0 : dataSets[index].data[i];
        dataSets[index].data[i + 1] = avg < tolerance ? 0 : dataSets[index].data[i + 1];
        dataSets[index].data[i + 2] = avg < tolerance ? 0 : dataSets[index].data[i + 2];
        index += 1;
      }
    }

    index = 0;

    for (let tolerance = 0; tolerance <= MAX_TOLERANCE; tolerance += TOLERANCE_INTERVAL) {
      const markers = detector.detect(dataSets[index]);

      index += 1;

      if (isAllMarkersDetected(markers)) {
        return markers;
      }
    }
    return false;
  };

  const getCorners = (markers) => {
    const markerPositions = {};

    markers.forEach((marker) => { markerPositions[marker.id] = marker; });

    const startPointX = Math.min(
      markerPositions[TOP_LEFT].corners[0].x,
      markerPositions[BOTTOM_LEFT].corners[3].x
    );

    const endPointX = Math.max(
      markerPositions[TOP_RIGHT].corners[1].x,
      markerPositions[BOTTOM_RIGHT].corners[2].x
    );

    const startPointY = Math.min(
      markerPositions[TOP_LEFT].corners[0].y,
      markerPositions[TOP_RIGHT].corners[1].y
    );

    const endPointY = Math.max(
      markerPositions[BOTTOM_LEFT].corners[3].y,
      markerPositions[BOTTOM_RIGHT].corners[2].y
    );

    return {
      x: startPointX,
      y: startPointY,
      width: endPointX - startPointX,
      height: endPointY - startPointY
    };
  };

  const stopCamera = (stream) => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  };

  const startCamera = () => {
    const video = videoRef.current;

    if (window.navigator.mediaDevices === undefined) {
      window.navigator.mediaDevices = {};
    }

    if (window.navigator.mediaDevices.getUserMedia === undefined) {
      window.navigator.mediaDevices.getUserMedia = (constraints) => {
        const getUserMedia = window.navigator.webkitGetUserMedia || window.navigator.mozGetUserMedia;
        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(window.navigator, constraints, resolve, reject);
        });
      };
    }

    window.navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if ('srcObject' in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream);
        }
        setMediaStream(stream);
      });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    startCamera();
  }, []);

  useEffect(() => {
    if (mediaStream) {
      let interval;
      const tick = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        if (video && video.readyState === video.HAVE_ENOUGH_DATA) {
          const context = canvas.getContext('2d');
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          const markers = detectMarkers();

          if (markers) {
            clearInterval(interval);
            const corners = getCorners(markers);
            context.drawImage(
              context.canvas,
              corners.x,
              corners.y,
              corners.width,
              corners.height,
              0,
              0,
              canvas.width,
              canvas.height
            );
            stopCamera(mediaStream);
            setHasCapture(true);
          }
        }
      };
      interval = setInterval(tick, (1000 / FRAME_RATE));
    }

    return () => {
      stopCamera(mediaStream);
    };
  }, [mediaStream]);

  const showScanPaperModal = () => {
    dispatch(constructModal({
      activate: true,
      canvasOnClick: false,
      content: <ScanPaperModal
        handleDownload={() => {
          window.open(`/${pdfHtml}?src=${scanPaperTemplate}`);
          dispatch(activateModal(false));
        }}
        handleCancel={() => { dispatch(activateModal(false)); }}
      />
    }));
  };

  /* eslint-disable jsx-a11y/media-has-caption */
  return (
    <div className={css['scan-edit']} ref={containerRef}>
      <div className={css['scan-overlay']} />
      <div className={css['scan-header']}>
        <Trans>Align a section of your Mathletics Paper with the area below</Trans>
      </div>
      <video className={css.hidden} autoPlay playsInline ref={videoRef} />
      <div className={css['camera-area']}>
        <div
          className={classNames(css.green, css['green-left'])}
          style={{
            backgroundImage: `url(${leftGreen})`
          }}
        />
        <canvas
          className={classNames({
            [css['has-capture']]: hasCapture
          })}
          ref={canvasRef}
        />
        <div
          className={classNames(css.green, css['green-right'])}
          style={{
            backgroundImage: `url(${rightGreen})`
          }}
        />
      </div>
      {hasCapture &&
        <BtnText
          className={css['btn-retake']}
          callBack={() => {
            setHasCapture(false);
            startCamera();
          }}
        >
          <Trans>Rescan work</Trans>
        </BtnText>
      }
      <div className={css['scan-footer']}>
        <BtnText
          callBack={() => {
            dispatch(widgetCancel());
            dispatch(resetMenu());
          }}
        >
          <Trans>Cancel</Trans>
        </BtnText>
        <BtnText callBack={showScanPaperModal} className={css['btn-print']}>
          <Trans>Print Mathletics Paper</Trans>
          <InlineSVG src={iconPrint} />
        </BtnText>
        <BtnText
          callBack={() => {
            dispatch(widgetSave({
              imageUrl: canvasRef.current.toDataURL(),
              canvas: canvasRef.current.toDataURL()
            }));
            dispatch(resetMenu());
          }}
          isDisabled={!hasCapture}
        >
          <Trans>Ok</Trans>
        </BtnText>
      </div>
    </div>
  );
});

export default ScanEdit;

