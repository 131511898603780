import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { AudioPlayer } from '../../audio-controller';
import css from './record-audio-view.scss';
import Resources from '../../../helpers/resources';

const RecordAudioView = ({ data }) => {
  const [audioUrl, setAudioUrl] = useState(data.audioUrl);
  const { girlId, userId } = useSelector(state => state.queryStringReducer);

  const updateAudioUrl = async () => {
    const userPrefix = userId ? `/users/${userId}` : '';
    setAudioUrl(
      // eslint-disable-next-line max-len
      `${window._env_.MLEGateway}${userPrefix}/resources/${girlId}/files/${data.audioId}?authToken=${Resources.env.authToken}`
    );
  };

  useEffect(() => {
    if (data.audioId) {
      updateAudioUrl();
    }
  }, []);

  return (
    <div className={css['record-audio-view']}>
      {
        audioUrl ? (
          <AudioPlayer src={audioUrl} />
        ) : (
          <p><Trans>No audio recorded</Trans></p>
        )
      }
    </div>
  );
};

RecordAudioView.propTypes = {
  data: PropTypes.object
};

export default RecordAudioView;
