import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import logo from '../../assets/images/mathletics-logo.svg';
import backIcon from '../../assets/images/back-icon.svg';
import css from './header.scss';
import { BtnIcon } from '../../components/buttons';
import Profile from '../profile/profile';
import { recordTime, TIMESTAMP_LEAVE, closeMle, completeActivity } from '../mle/mle-actions';
import { ACTIVITY_MARKED } from '../activity/activity-actions';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const Header = ({
  category = 'Category',
  title = 'Title'
}) => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const { activityReducer, queryStringReducer } = useSelector(state => state);
  const { activityStatus } = activityReducer;
  const { girlId, isPreview, userId } = queryStringReducer;

  const completeCallback = () => {
    if (window.history.length === 1) {
      window.close();
    } else {
      window.history.back();
    }
  };

  const handleClose = async () => {
    await dispatch(recordTime(TIMESTAMP_LEAVE));
    await dispatch(closeMle());
    if (activityStatus === ACTIVITY_MARKED && !isPreview && !userId) {
      await dispatch(completeActivity({
        girlId,
        completeCallback
      }));
      return;
    }
    completeCallback();
  };

  return (
    <div className={css.header}>
      <div className={css.left}>
        <BtnIcon
          title={i18n._(t`Back to Student Console`)}
          icon={backIcon}
          className={css.back}
          callBack={handleClose}
        />
        <BtnIcon icon={logo} className={css.logo} />
        <div className={css.text}>
          <p>{category}</p>
          <h1 className={css.title}>{title}</h1>
        </div>
      </div>
      <div className={css.right}>
        <Profile />
      </div>
    </div>
  );
};

Header.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string
};

export default Header;
