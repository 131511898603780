import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Resources from '../../../helpers/resources';
import css from './scan.scss';
import { constructModal, activateModal } from '../../modal/modal-actions';
import ImageModal from '../../../components/image-modal/image-modal';

const ScanView = ({ data }) => {
  const [imageUrl, setImageUrl] = useState(data.imageUrl);
  const { girlId, userId } = useSelector(state => state.queryStringReducer);
  const dispatch = useDispatch();

  const getImageUrl = () => {
    const userPrefix = userId ? `/users/${userId}` : '';
    const queryString = `?authToken=${Resources.env.authToken}&contentType=image/png`;
    setImageUrl(
      `${window._env_.MLEGateway}${userPrefix}/resources/${girlId}/files/${data.imageId}${queryString}`
    );
  };

  useEffect(() => {
    if (data.imageId) {
      getImageUrl();
    }
  }, []);

  const expandImage = () => {
    dispatch(constructModal({
      activate: true,
      canvasOnClick: false,
      content: <ImageModal
        url={imageUrl}
        handleCancel={() => { dispatch(activateModal(false)); }}
      />
    }));
  };

  return (
    <div
      className={css['scan-view']}
      onClick={expandImage}
      role="presentation"
      style={{
        backgroundImage: `url(${imageUrl})`
      }}
    />
  );
};

ScanView.propTypes = {
  data: PropTypes.object
};

export default ScanView;
